import {Component, EventEmitter, HostBinding, Input, OnChanges, OnInit, Output} from '@angular/core';
import {Subject} from 'rxjs';
import {FacilityChooserService, OrganizationFacility, OrganizationFacilityDetails} from '../../../../@core/facility-chooser.service';
import {takeUntil} from 'rxjs/operators';
import {UnsubscribeComponent} from '../../../../@core/fc-component';

interface FacilitySelectOptions extends OrganizationFacilityDetails {
    organizationName: string;
    id: OrganizationFacility;
}

@Component({
    selector: 'app-facility-select-addon',
    templateUrl: './facility-select-addon.component.html',
})
export class FacilitySelectAddonComponent extends UnsubscribeComponent implements OnInit, OnChanges {
    // If this subject emits a value the selected facility will be reset to it's initial value
    @Input() resetEmitter: Subject<null>;
    @Input() emitSingleFacility = true;
    @Input() multi = false;
    @Input() emitOnLoad = false;
    @Input() placeholder = 'All Facilities';
    @Input() facilitiesOnly = false;
    @Input() classes = 'body-select';

    @Input() orgFac: OrganizationFacility | OrganizationFacility[];
    @Output() orgFacChange = new EventEmitter<OrganizationFacility | OrganizationFacility[]>();

    facilityOptions: FacilitySelectOptions[];
    shouldShowFacilitySelect: boolean;

    @HostBinding('class.d-none') get hidden() {
        return !this.shouldShowFacilitySelect;
    }

    constructor(private fc: FacilityChooserService) {
        super();
    }

    ngOnChanges() {
        if (!this._hasOrgFac()) return;

        if (!Array.isArray(this.orgFac) && !this.multi) {
            this.orgFac = {organization: this.orgFac.organization, facility: this.orgFac.facility};
        } else if (!Array.isArray(this.orgFac) && this.multi) {
            this.orgFac = this.orgFac.organization && this.orgFac.facility ?
                [{organization: this.orgFac.organization, facility: this.orgFac.facility}] :
                [];
        } else if (Array.isArray(this.orgFac)) {
            this.orgFac = this.orgFac.map((of: any) => ({organization: of.organization, facility: of.facility}));
        }
    }

    ngOnInit(): void {
        this._processSelectFacilityOptions();
        this.shouldShowFacilitySelect = this.fc.options.length > 1;
        // Do not emit the only facility the user has, if we don't want to emit it
        if ((!this.emitSingleFacility && this.facilityOptions.length === 1)) return;

        if (this.resetEmitter) this.resetEmitter.pipe(takeUntil(this.destroy$)).subscribe(() => this.reset());

        if (this._hasOrgFac()) {
            this.orgFacChange.emit(this.orgFac);
        } else {
            this.reset();
        }
    }

    reset() {
        if (!this.multi) {
            this.orgFac = null;
        } else {
            this.orgFac = [];
        }
    }

    compareFacilityObjById(f1, f2) {
        if (f1 == null || f2 == null) {
            return false;
        } else if (f1.id && f1.id.facility === f2.facility && f1.id.organization === f2.organization) {
            return true;
        }
        return false;
    }

    onFacilityChange(orgFac: OrganizationFacility | OrganizationFacility[]) {
        this.orgFacChange.emit(orgFac || {organization: 0, facility: 0});
    }

    private _hasOrgFac(): boolean {
        const hasOrgFac = (orgFac: OrganizationFacility) =>
            (orgFac && 'facility' in orgFac && orgFac.facility) || (orgFac && 'organization' in orgFac && orgFac.organization);
        if (!this.multi && hasOrgFac((this.orgFac as OrganizationFacility))) {
            return true;
        } else if (this.multi && (this.orgFac as OrganizationFacility[])?.some(of => hasOrgFac(of))) {
            return true;
        }
        return false;
    }

    private _processSelectFacilityOptions() {
        this.facilityOptions = this.fc.options
            .filter(option => option.organization?.id && (!this.multi && !this.facilitiesOnly || option.facility?.id))
            .map(option => ({
                ...option,
                id: {
                    organization: option.organization.id,
                    facility: option.facility?.id,
                },
                organizationName: option.facility?.is_inactive ? 'Inactive Facilities' : option.organization.name,
            }));
    }
}
